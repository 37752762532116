



































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { getBlindBoxDetail, sendCode } from "@/api/activity.blind-box.api";
import SupplyPackageApi, { ISupplyBox } from "@/api/supply-package.api";
import DestinyCartoon from "@/views/destiny-draw/components/destinyFate/DestinyCartoon.vue";
import { Toast, Popup } from "vant";
import { dynamicImportScript } from "@/utils/script.util";
import InteractionLib from "@/utils/interaction.lib";
import { DOWNLOAD_APP } from "@/constant";
import { cloneDeep } from "lodash";

@Component({
  components: {
    DestinyCartoon,
    Popup,
  },
})
export default class BlindBoxV3 extends Vue {
  @Ref("cust-items") private custItemsRef!: HTMLDivElement;
  private private_id = this.$route.params?.private_id;
  private phoneStr = "";
  private items: Array<ISupplyBox> = [];
  private items_reverse: Array<ISupplyBox> = [];
  private agree = false;
  private mobile = InteractionLib.mobile;
  private channel = String(this.$route.query?.channel || "") || "gamepower";
  price = "";
  show = false;
  serviceShow = false;
  animation = { "--scroll-width": "-0px", "--play": "0s" };
  get downloadURL() {
    if (this.mobile.ios) return DOWNLOAD_APP.ios;
    return this.channel in DOWNLOAD_APP.android
      ? DOWNLOAD_APP.android[this.channel]
      : DOWNLOAD_APP.android.gamepower;
  }

  iknowHandler() {
    this.show = false;
  }

  private handleReceive() {
    if (!this.phoneStr || String(this.phoneStr).length !== 11) {
      Toast("请输入正确的手机号");
      return;
    }
    if (!this.agree) {
      Toast("请先同意用户协议");
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTimeout(() => {
      this.$router.push({
        name: "BlindBoxActivityPrivateIdDetail",
        params: {
          private_id: this.private_id,
          phone: this.phoneStr,
        },
        query: this.$route.query,
      });
    });
  } 
  boxItems:any = {}

  private async getBlindBoxInfo() {
    const box = await getBlindBoxDetail(this.private_id);
    this.boxItems = box
    this.price = String(box.price / 100);
    if(this.boxItems.activity_cover.length>0){
      const start = Math.floor(this.boxItems.activity_cover.length / 2);
      this.items = cloneDeep(this.boxItems.activity_cover).slice(0);
      this.items_reverse = cloneDeep(this.boxItems.activity_cover).reverse();
      const length = (this.boxItems.activity_cover.length + 10) * 38;
       const _els = document.querySelectorAll<HTMLDivElement>(".cust-items");
    _els.forEach((el) => {
      let i = 0;
      setInterval(() => {
        i = i + 0.3;
        el.style.transform = `translateX(-${i}px)`;
        if (i > length) {
          i = 0;
        }
      }, 1);
    });
    }else{
      const items = await SupplyPackageApi.getSupplyBoxList(box.id);
      const start = Math.floor(items.length / 2);
      this.items = cloneDeep(items).slice(0, start);
      this.items_reverse = cloneDeep(items).slice(start, -1);
      await this.$nextTick();
      const { scrollWidth, offsetWidth } = this.custItemsRef;
      const length = (items.length + 10) * 38;
       const _els = document.querySelectorAll<HTMLDivElement>(".cust-items");
    _els.forEach((el) => {
      let i = 0;
      setInterval(() => {
        i = i + 0.3;
        el.style.transform = `translateX(-${i}px)`;
        if (i > length) {
          i = 0;
        }
      }, 1);
    });
    }
    
    
   
  }

  private mounted() {
    localStorage.setItem(
      "track_id",
      String(this.$route.query?.track_id || "") || ""
    );
    localStorage.setItem(
      "channel",
      String(this.$route.query?.channel || "") || ""
    );
    localStorage.setItem("__channel", this.channel);
    this.getBlindBoxInfo();
  }

  toRule() {
    this.show = true;
  }

  toService() {
    this.serviceShow = true;
  }
}
